<template>
    <div class="project-tasks-statistics-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0; padding: 0;">
            <div class="left">
                <el-tabs class="filter-tabs task-status-tabs" v-model="activeFilter" @tab-click="filterClickEvent">
                    <el-tab-pane :label="$t('project.project_task_statistics_page_tab_pane_label_1')" name="ALL"></el-tab-pane>
                    <el-tab-pane :label="$t('project.project_task_statistics_page_tab_pane_label_2')" name="ASSIGNED"></el-tab-pane>
                    <el-tab-pane :label="$t('project.project_task_statistics_page_tab_pane_label_3')" name="IN_PROGRESS"></el-tab-pane>
                    <el-tab-pane :label="$t('project.project_task_statistics_page_tab_pane_label_4')" name="FINISHED"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="right" style="padding: 12px 0">
                <div style="width:220px;" class="margin-l-10">
                    <el-input :placeholder="$t('project.project_task_statistics_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.keyword" @input="inputSearchEvent"></el-input>
                </div>
                <!-- <div class="language-group margin-l-10">
                    <div style="width:125px;">
                        <el-select size="small" :placeholder="`源语言`" clearable v-model="searchForm.sourceLanguage" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.targetLanguage === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="margin-l-5 fonts-14">
                        <svg-icon name="ib-arrow-right" style="color:#00000033;"></svg-icon>
                    </div>
                    <div class="margin-l-5" style="width:125px;">
                        <el-select size="small" :placeholder="`目标语言`" clearable v-model="searchForm.targetLanguage" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.sourceLanguage === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div> -->
                <el-tooltip :content="$t('project.project_task_statistics_page_tips_1')" placement="top">
                    <el-button size="small" plain round icon="el-icon-refresh-left" class="margin-l-10" style="padding: 6.4px; font-size: 18px;" :loading="refreshLoading" @click="initPage(false)"></el-button>
                </el-tooltip>
                <div class="margin-l-10" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-popover placement="bottom" trigger="click">
                        <div class="more-filter-content">
                            <el-form label-width="90px" style="width: 290px;">
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_1')" class="margin-b-5">
                                <el-select v-model="searchForm.teamId" size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_1')" @change="selectSearchEvent" clearable >
                                    <template v-for="item in teamOptions">
                                        <el-option :key="item.value" :value="item.value" :label="item.title"></el-option>
                                    </template>
                                </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_2')" class="margin-b-5">
                                    <el-select v-model="searchForm.creatorId" filterable size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_2')" @change="selectSearchEvent" clearable>
                                        <template v-for="item in managerOptions">
                                            <el-option :key="item.value" :value="item.value" :label="item.title"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_3')" class="margin-b-5">
                                    <el-select v-model="searchForm.assigneeId" filterable size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_3')" @change="selectSearchEvent" clearable>
                                        <template v-for="item in memberOptions">
                                            <el-option :key="item.value" :value="item.value" :label="item.title"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_4')" class="margin-b-5">
                                    <el-select v-model="searchForm.taskType" size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_4')" @change="selectSearchEvent" clearable>
                                        <template v-for="item in taskTypeOptions">
                                            <el-option :key="item.code" :value="item.code" :label="item.desc"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_5')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_5')" clearable filterable v-model="searchForm.sourceLanguage" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.targetLanguage === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_6')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_6')" clearable filterable v-model="searchForm.targetLanguage" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.sourceLanguage === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_7')" class="margin-b-5">
                                    <el-date-picker v-model="searchForm.startTime" @change="selectSearchEvent" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" round size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_7')" clearable style="width: 100%;"></el-date-picker>
                                </el-form-item>
                                <el-form-item :label="$t('project.project_task_statistics_page_form_item_label_8')" class="margin-b-5">
                                    <el-date-picker v-model="searchForm.endTime" @change="selectSearchEvent" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" round size="small" :placeholder="$t('project.project_task_statistics_page_select_placeholder_8')" clearable style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-badge slot="reference" is-dot class="more-filter-badge" 
                            :hidden="!(searchForm.teamId || searchForm.creatorId || searchForm.assigneeId || searchForm.taskType || searchForm.sourceLanguage || searchForm.targetLanguage || searchForm.startTime || searchForm.endTime)">
                            <el-button plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-funnel"></svg-icon>
                            </el-button>
                        </el-badge>
                    </el-popover>
                </div>
                <div class="margin-l-10">
                    <el-button @click="downloadBtnEvent" :loading="downloadLoading" plain round size="small" style="padding: 6.4px; font-size: 18px;">
                        <svg-icon name="ib-download-1" v-if="!downloadLoading"></svg-icon>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="margin-t-15">
            <el-table ref="taskTableList" :data="list" row-key="taskId" @selection-change="handleSelectionChange" style="width: 100%">
                <el-table-column type="selection" :reserve-selection='true' width="50"></el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_1')" min-width="230" fixed :show-overflow-tooltip="false">
                    <template slot-scope="scope">
                        <div class="flex min-w-0 flex-align-center">
                        <div class="file-icon">
                            <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.docType] ? config.FILE_TYPE_MATCH['.' + scope.row.docType].icon : ''}`"></svg-icon>
                        </div>
                        <div class="flex-1 text-omission">
                            <el-tooltip placement="top-start" :content="scope.row.taskName">
                                <span>
                                    <template v-if="scope.row.status === 'ASSIGNED'">{{ scope.row.taskName }}</template>
                                    <template v-else>
                                        <router-link :to="`/workbench/editer?id=${scope.row.docId}&taskId=${scope.row.taskId}`" class="table-link">{{ scope.row.taskName }}</router-link>
                                    </template>
                                </span>
                            </el-tooltip>
                        </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_2')" fixed prop="assigneeName"  min-width="90">
                </el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_3')" prop="taskTypeDesc" width="100"></el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_4')" width="250" prop="projectName" show-overflow-tooltip></el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_5')" prop="creatorName" min-width="100"></el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_6')" min-width="110">
                    <template slot-scope="scope">
                        <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_7')" min-width="140">
                    <template slot-scope="scope">
                        <div class="fonts-12">
                            <template v-if="scope.row.acceptTime">
                                {{ scope.row.acceptTime }}
                            </template>
                            <template v-else>
                                <span class="color-999 fonts-12 text-italic">{{$t('project.project_task_statistics_page_tips_2')}}</span>
                            </template>
                            <br/>
                            <template v-if="scope.row.submitTime">{{ scope.row.submitTime }}</template>
                            <template v-else>
                                <span class="color-999 text-italic">{{$t('project.project_task_statistics_page_tips_3')}}</span>
                            </template>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_8')" prop="taskStatusDesc" min-width="90"></el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_9')" min-width="90">
                    <template slot-scope="scope">
                        {{ scope.row.process }}{{$t('project.project_task_statistics_page_tips_4')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('project.project_task_statistics_page_table_column_10')" prop="taskWords" min-width="90"></el-table-column>
                <!-- <template v-if="customized"> -->
                    <el-table-column :label="$t('project.project_task_statistics_page_table_column_11')" min-width="90">
                        <template slot-scope="scope">
                            {{ scope.row.valuationWords || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.project_task_statistics_page_table_column_12')" min-width="90">
                        <template slot-scope="scope">
                            {{ scope.row.price ? `${scope.row.price}/${scope.row.unitDesc}` : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.project_task_statistics_page_table_column_13')" min-width="90">
                        <template slot-scope="scope">
                            {{ scope.row.currencyDesc || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.project_task_statistics_page_table_column_14')" prop="" min-width="90">
                        <template slot-scope="scope">
                            {{ scope.row.totalPrice || '-' }}
                        </template>
                    </el-table-column>
                <!-- </template> -->
            </el-table>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import qs from 'querystring';
import { projectApi, commonApi } from '@/utils/api';
import FullLoading from '@/components/FullLoading';
import TaskTableList from './components/TaskStatisticsTableList';
import LanguageDirection from '@/components/LanguageDirection';
import config from '@/utils/config';
export default {
    components: {
        FullLoading, TaskTableList, LanguageDirection, 
    },
    data () {
        return {
            config,
            activeFilter: 'ALL',
            refreshLoading: false,
            searchForm: {
                keyword: '',
                sourceLanguage: '',
                targetLanguage: '',
                assigneeId: '',
                creatorId: '',
                taskType: '',
                teamId: '',
                startTime: '',
                endTime: '',
            },
            customized: false,
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 16,
            searchTimer: null,
            teamOptions: [],
            managerOptions: [],
            memberOptions: [],
            taskTypeOptions: [],
            downloadLoading: false,
            queryParams: {},
            selectedList: [],
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initPage(showFullLoading){
            if(showFullLoading){
                this.$refs.fullLoading.setShow();
            }
            console.log(this.searchForm);
            let url = `${projectApi.queryTaskStatisticsList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}`;
            let query = {queryType: 'ORG'};
            if(this.activeFilter !== 'ALL') {
                query['status'] = this.activeFilter;
            }
            if(this.searchForm.keyword) {
                query['keyword'] = this.searchForm.keyword;
            }
            if(this.searchForm.sourceLanguage) {
                query['sourceLanguage'] = this.searchForm.sourceLanguage;
            }
            if(this.searchForm.targetLanguage) {
                query['targetLanguage'] = this.searchForm.targetLanguage;
            }
            if(this.searchForm.taskType){
                query['taskType'] = this.searchForm.taskType;
            }
            if(this.searchForm.creatorId){
                query['creatorId'] = this.searchForm.creatorId;
            }
            if(this.searchForm.assigneeId){
                query['assigneeId'] = this.searchForm.assigneeId;
            }
            if(this.searchForm.teamId){
                query['teamId'] = this.searchForm.teamId;
            }
            if(this.searchForm.startTime){
                query['startTime'] = this.searchForm.startTime || '';
            }
            if(this.searchForm.endTime){
                query['endTime'] = this.searchForm.endTime || '';
            }
            this.queryParams = query;
            let queryStr = qs.stringify(query);
            url = `${url}${queryStr.length > 0 ? '&' : ''}${queryStr}`;
            this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.customized = res.data.customized;
                    this.list = res.data.page.records;
                    this.total = res.data.page.total;
                }
            }).finally(() => {
                this.refreshLoading = false;
                this.$refs.fullLoading.setHide();
            })
        },
        filterClickEvent() {
            this.pageNumber = 1;
            this.initPage(false);
        },
        
        initTaskTypeOptions () {
            let url = projectApi.queryTaskTypeList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.taskTypeOptions = res.data.taskType;
                }
            })
        },
        initFilterSelectOptions() {
            let url = projectApi.queryAllProjectTaskStatisticsFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamList;
                    this.managerOptions = res.data.managerList;
                    this.memberOptions = res.data.memberList;
                }
            })
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        inputSearchEvent(val){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.pageNumber = 1;
                self.initPage(false);
            }, 500)
        },
        selectSearchEvent(){
            this.$nextTick(()=> {
                this.pageNumber = 1;
                this.initPage(false);
            })
        },
        downloadBtnEvent() {
            // console.log(this.activeOrg);
            let ids = this.selectedList.map(item => item.taskId);
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: this.$t('project.project_task_statistics_page_method_downloadBtnEvent_title'),
                metadataIdList: [this.userInfo.memberId],
                metadataType: "TMS_TASK_STATISTICS",
                moreOptions: {
                    ...this.queryParams,
                    orgId: this.activeOrg.organizationId,
                    taskIds: ids,
                },
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                    this.selectedItem = [];
                    this.resetFileList = new Date().getTime();
                    this.$refs.taskTableList.resetTableSelected();
                }
            }).finally(()=>{
                this.downloadLoading = false;
            });
        },
        handleSelectionChange (val) {
            this.selectedList = val;
        }
    },
    created() {
        
    },
    async mounted() {
        this.initPage(true);
        this.initTaskTypeOptions();
        this.initFilterSelectOptions();
        
    }
}
</script>
<style lang="scss" scoped>
.project-tasks-statistics-page{
    .task-status-tabs {
        ::v-deep .el-tabs__nav-wrap {
            &::after {
                display: none;
            }
        }
        ::v-deep .el-tabs__item {
            height: 57.1px;
            line-height: 57.1px;
        }
    }
    .task-card-list{
        margin: 30px 90px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
        .item {
            // min-height: 228px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px 15px 20px 15px;
            cursor: pointer;
            position: relative;
            .task-type-label {
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                font-size: 12px;
                color: #1AC49C;
                background-color: #DDF3EB;
                border-radius: 10px 0 10px 0;
                &.rejected {
                    color: #F56C6C;
                    background-color: #FDE2E2;
                }
            }
            .icon-content{
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: #F8F8F8;
                font-size: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .go-btn{
                width: 50px;
                height: 24px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                background-color: #f5f5f5;
            }
            .TO_START {
                color: #FE8E0B;
            }
            .IN_PROGRESS {
                color: #2979FF;
            }
            .COMPLETED,
            .FINISHED {
                color: #00B670;
            }
            .ASSIGNED {
                color: #FF9900;
            }
            .REJECTED {
                color: #999999;
            }
            .progress-text {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            ::v-deep .el-progress-bar__outer{
                background-color: #F8F8F8;
            }
        }
    }
}
.more-filter-content {
    display: flex;
    flex-direction: column;
    ::v-deep .el-select,
    ::v-deep .el-date-editor {
        .el-input__inner {
            // border-radius: 20px;
        }
    }
}
.more-filter-badge {
    ::v-deep .el-badge__content.is-dot {
        top: 5px;
        right: 8px;
    }
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
</style>
